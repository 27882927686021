import styled from 'styled-components'
import { Box, Flex, Text, Heading, Link } from '@kaiso/uikit'
import { useTranslation } from 'contexts/Localization'

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBorder};
  height: 1px;
  margin: 40px 0;
  width: 100%;
`

const BulletList = styled.ul`
  list-style-type: none;
  margin-left: 8px;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
  }
  li::before {
    content: '•';
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  li::marker {
    font-size: 12px;
  }
`

const StepContainer = styled(Flex)`
  gap: 24px;
  width: 100%;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`

const StyledStepCard = styled(Box)`
  display: flex;
  align-self: baseline;
  position: relative;
  background: ${({ theme }) => theme.colors.cardBorder};
  padding: 1px 1px 3px 1px;
  border-radius: ${({ theme }) => theme.radii.card};
`

const StepCardInner = styled(Box)`
  width: 100%;
  padding: 24px;
  background: ${({ theme }) => theme.card.background};
  border-radius: ${({ theme }) => theme.radii.card};
`

type Step = { title: string; subtitle: string; label: string }

const StepCard: React.FC<{ step: Step }> = ({ step }) => {
  return (
    <StyledStepCard width="100%">
      <StepCardInner height={['200px', '180px', null, '200px']}>
        <Text mb="16px" fontSize="12px" bold textAlign="right" textTransform="uppercase">
          {step.label}
        </Text>
        <Heading mb="16px" scale="lg" color="secondary">
          {step.title}
        </Heading>
        <Text color="textSubtle">{step.subtitle}</Text>
      </StepCardInner>
    </StyledStepCard>
  )
}

const InlineLink = styled(Link)`
  display: inline;
`

const GappedFlex = styled(Flex)`
  gap: 24px;
`

const HowTo: React.FC = () => {
  const { t } = useTranslation()

  const steps: Step[] = [
    {
      label: t('Step %number%', { number: 1 }),
      title: t('Prepare Data'),
      subtitle: t(
        'Provide token contract (for ERC20) + list of addresses & amounts for airdrop, separated with comma.',
      ),
    },
    {
      label: t('Step %number%', { number: 2 }),
      title: t('Check for Errors'),
      subtitle: t('Check if there are errors and duplicates and correct accordingly.'),
    },
    {
      label: t('Step %number%', { number: 3 }),
      title: t('Approve and Confirm'),
      subtitle: t('Enable Kaiso MultiSender contract to use your token. Confirm transfer transaction.'),
    },
  ]
  return (
    <Box width="100%">
      <Flex mb="40px" alignItems="center" flexDirection="column">
        <Heading mb="24px" scale="xl" color="secondary">
          {t('Kaiso MultiSender ')}
        </Heading>

        <Text textAlign="center">{t('Sending tokens in bulks in Astar Network has never been so easy.')}</Text>
        <Text textAlign="center">
          {t('Follow the following 3 easy steps to send ASTR and ERC20 tokens to multiple addresses.')}
        </Text>
        <Text>{t('Simple!')}</Text>
      </Flex>
      <StepContainer>
        {steps.map((step) => (
          <StepCard key={step.label} step={step} />
        ))}
      </StepContainer>
      {/* <Divider />
      <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="secondary">
            {t('Prepare Data')}
          </Heading>
          <Heading mb="24px" scale="md">
            {t('Subject.')}
          </Heading>
          <Text mb="16px" color="textSubtle">
            {t('Description.')}
          </Text>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet A')}
              </Text>
            </li>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet B')}
              </Text>
            </li>
          </BulletList>
          <Heading mb="24px" scale="md">
            {t('Subject.')}
          </Heading>
          <Text mb="16px" color="textSubtle">
            {t('Description.')}
          </Text>
        </Flex>
      </GappedFlex>
      <Divider />
      <GappedFlex flexDirection={['column', 'column', 'column', 'row']}>
        <Flex flex="2" flexDirection="column">
          <Heading mb="24px" scale="lg" color="secondary">
            {t('Check for Errors')}
          </Heading>
          <Text color="textSubtle">{t('Description Description Description:')}</Text>
          <Heading my="16px" scale="md">
            {t('Subject 1')}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet list.')}
              </Text>
            </li>
          </BulletList>
          <Heading my="16px" scale="md">
            {t('Subject 2')}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet 1 description.')}
              </Text>
            </li>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet 2 description.')}
              </Text>
            </li>
          </BulletList>
          <Heading my="16px" scale="md">
            {t('Subject 3')}
          </Heading>
          <BulletList>
            <li>
              <Text display="inline" color="textSubtle">
                {t('Bullet description ')}
                <InlineLink href="https://kaiso.finance">{t('Inline Link')}</InlineLink>
              </Text>
            </li>
          </BulletList>
        </Flex>
      </GappedFlex>
      <Divider /> */}
    </Box>
  )
}

export default HowTo
