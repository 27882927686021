import ConnectWalletButton from 'components/ConnectWalletButton'
import { Flex, Button } from '@kaiso/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@web3-react/core'
import useTheme from 'hooks/useTheme'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import HowTo from '../MultiSender/components/HowTo'
import Footer from './components/Footer'

const Home: React.FC = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  return (
    <>
      <PageMeta />

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        background={
          theme.isDark
            ? 'linear-gradient(180deg, #09070C 22%, #201335 100%)'
            : 'linear-gradient(180deg, #FFFFFF 22%, #D7CAEC 100%)'
        }
        index={2}
        hasCurvedDivider={false}
      >
        <HowTo />
        <Flex mt={25}>
          {!account && <ConnectWalletButton mr="8px" />}
          <NextLinkFromReactRouter to="/multisender">
            <Button variant={!account ? 'secondary' : 'primary'}>{t('Send tokens')}</Button>
          </NextLinkFromReactRouter>
        </Flex>
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="linear-gradient(180deg, #7645D9 0%, #5121B1 100%)"
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection>
    </>
  )
}

export default Home
